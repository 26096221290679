import { reward_wheel_axios } from "../main";

const getWheelData = () => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.get("/api/consumer/getallrewardwheels")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getWheelDivisionsData = (payload) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/getrewardwheeldefinitions", payload)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const rewardSpinEvent = (props) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/rewardspinevent", props)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};


const getSpinData = (props) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.get("/api/consumer/userspinsavailable", props)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const usedRewardPoint = () => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.get("/api/consumer/rewardpointused")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const userRewards = () => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.get("/api/consumer/getuserrewards")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const wheelPoints = () => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.get("/api/consumer/rewardwheelpoints")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const rewardPointHistory = (params) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/rewardpointhistory", params)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const transactionRewardPoint = (props) => {
    return new Promise((res, rej) => {
        axios.post("/gettransactionrewardpoint", props)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const acceptDeclineInvitation = (props) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/rewardinvitation", props)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const inviteConusmerForRewardWheelAfterTransaction = () => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post("/inviteconusmerforrewardwheelaftertransaction")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const reSpin = (props) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/respin", props)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getLastFewspinResults = (props) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/getlastfewspinresults", props)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getLastSpinWheelId = () => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/gettheoldestspinwheel")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getUserSpinResult = (params) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/getuserspinresult", params)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const updateUserSpinResult = (params) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/updatespinresult", params)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getConsumerBankStatus = () => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post('api/consumer/getconsumerbankstatus')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

export default {
    getWheelData,
    getWheelDivisionsData,
    rewardSpinEvent,
    getSpinData,
    usedRewardPoint,
    userRewards,
    wheelPoints,
    rewardPointHistory,
    transactionRewardPoint,
    acceptDeclineInvitation,
    inviteConusmerForRewardWheelAfterTransaction,
    reSpin,
    getLastFewspinResults,
    getLastSpinWheelId,
    getUserSpinResult,
    updateUserSpinResult,
    getConsumerBankStatus
};